import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import signImage from "../../assets/sign.jpg";
import { welcomeText } from "../constants/textCostants"; // Import translations
import { useLanguage } from "../constants/LanguageContext"; // Import language context
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Fade, Slide } from "react-awesome-reveal";

const Welcome = () => {
  const { language } = useLanguage();
  const welcome = welcomeText[language] || welcomeText["en"]; // Fallback to English if language is missing

  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/blog");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        gap: 3,
        maxWidth: {
          xs: "100%",
          md: "80%",
        },
        margin: "auto",
        padding: "40px",
        paddingBlock: "50px",
        alignItems: "center",
      }}
    >
      <Slide direction="left" delay={1000}>
        <Fade delay={1000}>
          {/* Text Content Section */}
          <Box
            sx={{
              flex: 1,
              textAlign: "left",
              backgroundColor: "#f9f9f9",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Title */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                position: "relative",
                padding: "20px 0 20px 0",
              }}
            >
              <ArrowDownwardIcon
                sx={{
                  transform: "rotate(-45deg)",
                  fontSize: "30px",
                  marginRight: "8px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  textTransform: "uppercase",
                }}
              >
                {welcome.title}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                fontFamily: "Montserrat, sans-serif",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              {welcome.content}
            </Typography>
            <Link
              to="/blog"
              style={{ textDecoration: "none" }}
              aria-label="Läs mer om vår blogg"
            >
              <Button
                variant="contained"
                onClick={handleReadMoreClick}
                color="primary"
                sx={{
                  marginTop: 2,
                  backgroundColor: "#4e4d4d",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#7e7e7e",
                    color: "black",
                  },
                }}
              >
                {welcome.button}
              </Button>
            </Link>
          </Box>
        </Fade>
      </Slide>

      <Slide direction="right" delay={1000}>
        <Fade delay={1000}>
          {/* Image Section */}
          <Box
            sx={{
              flex: 1,
              textAlign: "center",
              marginBottom: "auto",
              backgroundColor: "#f9f9f9",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              component="img"
              src={signImage}
              alt="Professionell hemsida utvecklad på kort tid för företag i Stockholm"
              sx={{
                maxWidth: "100%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Fade>
      </Slide>
    </Box>
  );
};

export default Welcome;
