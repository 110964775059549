import React from "react";
import { Box, Typography } from "@mui/material";
import imagePlaceholder from "../../assets/bg.jpg";

const HeroAbout = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "400px", // Adjust height as needed
        backgroundImage: `url(${imagePlaceholder})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark gray overlay with opacity
          zIndex: 1,
        }}
      />

      {/* Text Content */}
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          textAlign: "center",
          color: "white",
          padding: "20px",
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontWeight: "bold", fontSize: "36px", mb: 2 }}
        >
          CREATING DIGITAL SOLUTIONS
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "500" }}>
          Fullstack Developer focused on scalable and user-friendly solutions.
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroAbout;
