import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CodeIcon from "@mui/icons-material/Code";
import SecurityIcon from "@mui/icons-material/Security";
import DevicesIcon from "@mui/icons-material/Devices";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloudIcon from "@mui/icons-material/Cloud";
import StorageIcon from "@mui/icons-material/Storage";
import { everything } from "../constants/textCostants"; // Import your translations
import { useLanguage } from "../constants/LanguageContext"; // Import language context
import { Link } from "react-router-dom";

// CSS animation keyframes
const fadeInStyle = `
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

const EverythingYouNeed = () => {
  const { language } = useLanguage(); // Get the current language
  const [isVisible, setIsVisible] = useState(false); // Track visibility state
  const ref = useRef(null); // Ref for the component

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true); // Trigger animation
          observer.disconnect(); // Disconnect to prevent repeat
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={ref} // Attach the ref to the component
      sx={{
        backgroundColor: "#f9f9f9",
        padding: "50px 20px",
        color: "black",
        textAlign: "center",
      }}
    >
      {/* Inject Keyframes into the DOM */}
      <style>{fadeInStyle}</style>

      {/* Title */}
      <Box sx={{ maxWidth: "1200px", margin: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
            padding: "20px 20px",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(45deg)",
              fontSize: "30px",
              marginRight: "8px",
              color: "#2b2b2b",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "black",
              textTransform: "uppercase",
            }}
          >
            {everything.title[language]}
          </Typography>
        </Box>
      </Box>

      {/* Services Section */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {everything.services.map((service, index) => (
          <Box
            key={index}
            sx={{
              width: {
                xs: "100%", // Full width on xs screens
                sm: "calc(50% - 20px)", // Half width on sm screens
                md: "calc(33% - 20px)", // One-third width on larger screens
              },
              minWidth: "300px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              opacity: 0, // Start hidden
              animation: isVisible ? `fadeIn 0.5s ease-out forwards` : "none", // Trigger animation only when visible
              animationDelay: `${index * 0.2}s`, // Sequential delay
            }}
          >
            {/* Render appropriate icon */}
            {service.icon === "CodeIcon" && (
              <CodeIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}
            {service.icon === "DevicesIcon" && (
              <DevicesIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}
            {service.icon === "SecurityIcon" && (
              <SecurityIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}
            {service.icon === "CloudIcon" && (
              <CloudIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}
            {service.icon === "StorageIcon" && (
              <StorageIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}
            {service.icon === "SupportAgentIcon" && (
              <SupportAgentIcon sx={{ fontSize: "40px", color: "#b0b0b0" }} />
            )}

            {/* Service Title */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              {service.title[language]}
            </Typography>

            {/* Service Description */}
            <Typography
              variant="body2"
              sx={{
                marginTop: "10px",
                color: "#666",
              }}
            >
              {service.description[language]}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Call to Action */}
      <Box sx={{ marginTop: "40px" }}>
        <Button
          component={Link}
          to="/contact" // Link to /contact
          variant="outlined"
          sx={{
            borderColor: "#0bb822",
            background: "#0bb822",
            color: "#313030",
            padding: "10px 20px",
            borderRadius: "50px",
            textTransform: "none",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#b0b0b0",
              color: "#fff",
            },
          }}
        >
          {language === "sv"
            ? "Boka en kostnadsfri konsultation"
            : language === "ru"
            ? "Забронируйте бесплатную консультацию"
            : language === "uk"
            ? "Забронюйте безкоштовну консультацію"
            : language === "da"
            ? "Book en gratis konsultation"
            : "Book a Free Consultation"}
        </Button>
      </Box>
    </Box>
  );
};

export default EverythingYouNeed;
