import React from "react";
import { Box, Typography } from "@mui/material";
import backgroundImage from "../../assets/44.jpg";
import { cookiesContent } from "../../components/constants/textCostants"; // Import translations
import { useLanguage } from "../../components/constants/LanguageContext"; // Language context

function Cookies() {
  const { language } = useLanguage();
  const content = cookiesContent[language] || cookiesContent["en"]; // Fallback to English

  return (
    <Box>
      {/* Background Image */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "30vh",
          width: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#ffffff",
            textAlign: "center",
            lineHeight: "30vh",
          }}
        >
          {content.title}
        </Typography>
      </Box>

      {/* Content Section */}
      <Box
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "40px",
          maxWidth: "900px",
          margin: "-50px auto 40px",
          fontFamily: "Roboto, sans-serif",
          lineHeight: "1.6",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            marginBottom: "40px",
            color: "#666666",
          }}
        >
          {content.intro}
        </Typography>

        {content.sections.map((section, index) => (
          <Box key={index} sx={{ marginBottom: "30px" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
                color: "#000000",
              }}
            >
              {section.heading}
            </Typography>
            <Typography sx={{ color: "#333333" }}>{section.content}</Typography>
            {section.browsers && (
              <ul>
                {section.browsers.map((browser, i) => (
                  <li key={i}>
                    <Typography
                      sx={{
                        color: "#007BFF",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {browser}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Cookies;
