import React from "react";
import { Box, Typography } from "@mui/material";
import businessImage from "../../assets/alina.jpg";
import { welcomeAbout } from "../constants/textCostants";
import { useLanguage } from "../constants/LanguageContext";

export default function WelcomeAbout() {
  const { language } = useLanguage();
  const content = welcomeAbout[language] || welcomeAbout.en;

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        position: "relative",
        backgroundColor: "rgb(255, 255, 255)",
        padding: "50px 20px",
        color: "black",
        marginTop: "50px",
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      {/* Content Section */}
      <Box
        sx={{
          flex: 1,
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontStyle: "italic",
            padding: "0 20px",
          }}
        >
          {content.text}
        </Typography>
      </Box>

      {/* Circular Image Section */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "250px",
            height: "250px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img
            src={businessImage}
            alt="Business Inspiration"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
