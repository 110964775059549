import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { actionContent } from "../constants/textCostants"; // Import translations
import { useLanguage } from "../constants/LanguageContext"; // Import language context
import { Fade, Slide } from "react-awesome-reveal";

const Action = () => {
  const { language } = useLanguage(); // Get the current language
  const action = actionContent[language] || actionContent["en"]; // Fallback to English if language is missing

  return (
    <Slide direction={"up"}>
      <Box
        sx={{
          textAlign: "center",
          padding: "30px 20px",
          margin: "30px 0 0",
        }}
      >
        <Fade cascade>
          {/* Action Text */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontFamily: '"Montserrat", serif',
            }}
          >
            {action.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "20px",
              color: "#555",
              fontSize: "16px",
            }}
          >
            {action.subtitle}
          </Typography>
        </Fade>
        {/* Action Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0bb822",
            color: "white",
            padding: "10px 20px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "5px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#089e1c",
            },
          }}
          href="/contact"
        >
          {action.button}
        </Button>
      </Box>
    </Slide>
  );
};

export default Action;
