import React from "react";
import { Box } from "@mui/material";
import Hero from "../../components/Hero";
import Welcome from "../../components/homeComponents/Welcome";
import Services from "../Services";
import Reviews from "../../components/homeComponents/Reviews";
import CookieConsent from "../../components/homeComponents/CookieConsent";
import EverythingYouNeed from "../../components/homeComponents/EverythingYouNeed";
import Process from "../../components/homeComponents/Process";
import Action from "../../components/homeComponents/Action";

function Home() {
  return (
    <Box
      sx={{
        height: "auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Page Content */}
      <Hero />
      <Action />
      <Welcome />
      <EverythingYouNeed />
      <Process />
      <Services />
      <Reviews />

      {/* Cookie Consent Popup */}
      <CookieConsent />
    </Box>
  );
}

export default Home;
