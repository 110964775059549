import React from "react";

import { Box } from "@mui/material";
import HeroAbout from "../../components/about/HeroAbout";
import Second from "../../components/homeComponents/Second";
import WelcomeAbout from "../../components/about/WelcomeAbout";
import Red from "../../components/homeComponents/Red";

function About() {
  return (
    <Box>
      <HeroAbout />
      <WelcomeAbout />
      <Second />
      <Red />
    </Box>
  );
}

export default About;
