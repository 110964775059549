import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CodeIcon from "@mui/icons-material/Code";
import SearchIcon from "@mui/icons-material/Search";
import SupportIcon from "@mui/icons-material/Support";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import bg from "../assets/room2.png";
import { useLanguage } from "../components/constants/LanguageContext"; // Import language context
import { services } from "../components/constants/textCostants"; // Import translated services data

// Define the icon and styling data for each service
const servicesStyleData = [
  {
    id: 1,
    icon: <DesignServicesIcon fontSize="large" />,
    background: "linear-gradient(135deg, #4a4a4a, #2d2d2d)",
    textColor: "#ffffff",
  },
  {
    id: 2,
    icon: <CodeIcon fontSize="large" />,
    background: "linear-gradient(135deg, #5a5a5a, #3a3a3a)",
    textColor: "#ffffff",
  },
  {
    id: 3,
    icon: <SearchIcon fontSize="large" />,
    background: "linear-gradient(135deg, #333333, #1c1c1c)",
    textColor: "#ffffff",
  },
  {
    id: 4,
    icon: <LanguageIcon fontSize="large" />,
    background: "linear-gradient(135deg, #616161, #4a4a4a)",
    textColor: "#ffffff",
  },
  {
    id: 5,
    icon: <SupportIcon fontSize="large" />,
    background: "linear-gradient(135deg, #e0e0e0, #d3d3d3)",
    textColor: "#333333",
  },
];

const Services = () => {
  const { language } = useLanguage(); // Get the current language
  const servicesData = services[language] || services["en"]; // Use selected language or fallback to English

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 4,
        background: "linear-gradient(135deg, #121212, #333333, #1c1c1c)",
        color: "#ffffff",
        backgroundImage: `url(${bg})`,
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        paddingBlock: "100px",
      }}
    >
      <Box sx={{ maxWidth: "1200px", margin: "auto" }}>
        {/* Title */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
            padding: "20px 0 20px 0",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(45deg)",
              fontSize: "30px",
              marginRight: "8px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "white",
              textTransform: "uppercase",
            }}
          >
            {services.title[language]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            justifyContent: "center",
          }}
        >
          {servicesData.map((service, index) => {
            const styleData = servicesStyleData[index];

            return (
              <Box
                key={index}
                sx={{
                  flex: "1 1 100%",
                  maxWidth: "100%",
                  "@media (min-width: 600px)": {
                    flex: "1 1 48%",
                  },
                  "@media (min-width: 900px)": {
                    flex: "1 1 30%",
                  },
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <Card
                  sx={{
                    background: styleData.background,
                    color: styleData.textColor,
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "300px",
                  }}
                >
                  <Box
                    sx={{
                      mb: 2,
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        transform: "rotate(10deg) scale(1.2)",
                      },
                    }}
                  >
                    {styleData.icon}
                  </Box>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={
                        styleData.textColor === "#ffffff"
                          ? "inherit"
                          : "text.secondary"
                      }
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
