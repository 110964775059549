import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function CookieConsent({ onAccept }) {
  const [isVisible, setIsVisible] = useState(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    return !hasAcceptedCookies;
  });

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
    onAccept();
  };

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#222",
        color: "#FFF",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1000,
        boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.3)",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: 1.5,
          marginBottom: { xs: "8px", sm: "0" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        We use cookies to enhance your browsing experience. By continuing to use
        this website, you agree to our use of cookies. Learn more about cookies{" "}
        <Link
          to="/cookies"
          style={{ color: "#55c764", textDecoration: "underline" }}
        >
          here
        </Link>
        .
      </Typography>
      <Button
        onClick={handleAccept}
        sx={{
          backgroundColor: "#55c764",
          color: "#222",
          fontWeight: "bold",
          borderRadius: "4px",
          padding: "6px 16px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#55c764",
          },
        }}
      >
        OK
      </Button>
    </Box>
  );
}

export default CookieConsent;
