import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
} from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLanguage } from "../constants/LanguageContext";
import { processSteps } from "../constants/textCostants";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// CSS keyframes for the wave effect
const waveAnimation = `
@keyframes wave {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
`;

const Process = () => {
  const { language } = useLanguage(); // Get the current language
  const { title, steps } = processSteps[language] || processSteps["en"]; // Destructure title and steps from selected language
  const [isVisible, setIsVisible] = useState(false); // Track visibility state
  const ref = useRef(null); // Ref for the component

  const circleColors = [
    "#d3d3d3", // Light gray
    "#a9a9a9",
    "#808080",
    "#505050",
    "#0bb822", // Dark green
  ];

  const VerticalConnector = styled(StepConnector)(({ theme }) => ({
    [`& .MuiStepConnector-line`]: {
      borderColor: "#ccc",
      borderWidth: 2,
      minWidth: "30px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  }));

  const HorizontalConnector = styled(StepConnector)(({ theme }) => ({
    [`& .MuiStepConnector-line`]: {
      borderColor: "#ccc",
      borderWidth: 2,
      maxWidth: "100px",
      padding: "20px",
      margin: "auto",
    },
  }));

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true); // Trigger animation
          observer.disconnect(); // Disconnect after triggering to prevent repeat
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={ref} // Attach the ref to the component
      sx={{
        backgroundColor: "#f5f5f5",
        padding: "50px 20px",
        textAlign: "center",
        borderRadius: "12px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        margin: "50px auto",
        maxWidth: "1200px",
        position: "relative",
      }}
    >
      {/* Inject Keyframes into the DOM */}
      <style>{waveAnimation}</style>

      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "relative",
          padding: "20px 0 20px 0",
        }}
      >
        <ArrowDownwardIcon
          sx={{
            transform: "rotate(-45deg)",
            fontSize: "30px",
            marginRight: "8px",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "black",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Stepper */}
      <Stepper
        alternativeLabel={!isSmallScreen}
        orientation={isSmallScreen ? "vertical" : "horizontal"}
        connector={
          isSmallScreen ? <VerticalConnector /> : <HorizontalConnector />
        }
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        {steps.map((step, index) => (
          <Step key={index} active={true}>
            <StepLabel
              StepIconComponent={() => (
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                    border: `5px solid ${circleColors[index]}`,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: isVisible
                      ? `wave 0.6s ease-out forwards`
                      : "none", // Trigger animation only when visible
                    animationDelay: `${index * 0.3}s`, // Sequential delay
                    opacity: isVisible ? 0 : 1, // Set opacity initially
                  }}
                />
              )}
              sx={{
                ".MuiStepLabel-label": {
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  color: "#555",
                  marginTop: isSmallScreen ? "10px" : 0,
                },
              }}
            >
              {step}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Process;
