// import React, { useState } from "react";
// import { Box, TextField, Button, Typography } from "@mui/material";
// import emailjs from "emailjs-com";
// import backgroundImage from "../assets/44.jpg";

// // Define environment variables at the top
// const SERVICE_ID = process.env.REACT_APP_SERVICEID;
// const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
// const USER_ID = process.env.REACT_APP_USERID;

// const ContactPage = () => {
//   const [formValues, setFormValues] = useState({
//     name: "",
//     email: "",
//     description: "",
//   });

//   const [formErrors, setFormErrors] = useState({
//     name: false,
//     email: false,
//     description: false,
//   });

//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({ ...formValues, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const errors = {
//       name: formValues.name === "",
//       email: formValues.email === "",
//       description: formValues.description === "",
//     };
//     setFormErrors(errors);

//     if (!Object.values(errors).includes(true)) {
//       emailjs
//         .send(
//           SERVICE_ID,
//           TEMPLATE_ID,
//           {
//             from_name: formValues.name,
//             from_email: formValues.email,
//             message: formValues.description,
//           },
//           USER_ID
//         )
//         .then((result) => {
//           setSuccessMessage("Message successfully sent!");
//           setErrorMessage("");
//           setFormValues({ name: "", email: "", description: "" });
//         })
//         .catch((error) => {
//           setErrorMessage("Failed to send the message. Please try again.");
//           setSuccessMessage("");
//         });
//     }
//   };

//   return (
//     <Box sx={{ height: "auto" }}>
//       <Box
//         sx={{
//           width: "100%",
//           height: "400px",
//           backgroundImage: `url(${backgroundImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           position: "relative",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <Box
//           sx={{
//             position: "relative",
//             zIndex: 2,
//             textAlign: "center",
//             color: "white",
//             padding: "100px 20px",
//             fontFamily: "Montserrat, sans-serif",
//           }}
//         >
//           <Typography
//             variant="h2"
//             sx={{ fontWeight: "bold", fontSize: "36px", mb: 2 }}
//           >
//             BOOK A FREE CONSULTATION TODAY
//           </Typography>
//           <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: "500" }}>
//             Have any questions or want to work together? Drop me a message
//             below.
//           </Typography>
//         </Box>
//       </Box>
//       <Box
//         sx={{
//           width: { xs: "90%", sm: "70%", md: "50%" },
//           margin: "auto",
//           padding: 4,
//           marginBlock: "200px",
//           backgroundColor: "#2c2c2c",
//           borderRadius: 2,
//           boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
//           color: "white",
//           textAlign: "center",
//         }}
//       >
//         <Typography variant="body1" gutterBottom sx={{ marginBottom: 3 }}>
//           Have any questions or want to work together? Drop me a message below.
//         </Typography>

//         <form onSubmit={handleSubmit}>
//           <TextField
//             label="Name"
//             name="name"
//             variant="outlined"
//             fullWidth
//             required
//             margin="normal"
//             value={formValues.name}
//             onChange={handleChange}
//             error={formErrors.name}
//             helperText={formErrors.name ? "Name is required" : ""}
//             InputLabelProps={{ style: { color: "white" } }}
//             sx={{
//               "& .MuiInputBase-input": { color: "white" },
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": { borderColor: "white" },
//                 "&:hover fieldset": { borderColor: "#7e7e7e" },
//                 "&.Mui-focused fieldset": { borderColor: "white" },
//               },
//             }}
//           />
//           <TextField
//             label="Email"
//             name="email"
//             variant="outlined"
//             fullWidth
//             required
//             margin="normal"
//             value={formValues.email}
//             onChange={handleChange}
//             error={formErrors.email}
//             helperText={formErrors.email ? "Email is required" : ""}
//             InputLabelProps={{ style: { color: "white" } }}
//             sx={{
//               "& .MuiInputBase-input": { color: "white" },
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": { borderColor: "white" },
//                 "&:hover fieldset": { borderColor: "#7e7e7e" },
//                 "&.Mui-focused fieldset": { borderColor: "white" },
//               },
//             }}
//           />
//           <TextField
//             label="Message"
//             name="description"
//             variant="outlined"
//             fullWidth
//             required
//             margin="normal"
//             multiline
//             rows={4}
//             value={formValues.description}
//             onChange={handleChange}
//             error={formErrors.description}
//             helperText={formErrors.description ? "Message is required" : ""}
//             InputLabelProps={{ style: { color: "white" } }}
//             sx={{
//               "& .MuiInputBase-input": { color: "white" },
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": { borderColor: "white" },
//                 "&:hover fieldset": { borderColor: "#7e7e7e" },
//                 "&.Mui-focused fieldset": { borderColor: "white" },
//               },
//             }}
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             sx={{
//               marginTop: 2,
//               backgroundColor: "#0bb822",
//               color: "white",
//               "&:hover": {
//                 backgroundColor: "#079e1a",
//               },
//             }}
//           >
//             Submit
//           </Button>
//         </form>

//         {/* Display success or error messages */}
//         {successMessage && (
//           <Typography sx={{ color: "#0bb822", marginTop: 2 }}>
//             {successMessage}
//           </Typography>
//         )}
//         {errorMessage && (
//           <Typography sx={{ color: "red", marginTop: 2 }}>
//             {errorMessage}
//           </Typography>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default ContactPage;

import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import emailjs from "emailjs-com";
import backgroundImage from "../assets/44.jpg";

// Define environment variables at the top
const SERVICE_ID = process.env.REACT_APP_SERVICEID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
const USER_ID = process.env.REACT_APP_USERID;

const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    description: false,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {
      name: formValues.name === "",
      email: formValues.email === "",
      description: formValues.description === "",
    };
    setFormErrors(errors);

    if (!Object.values(errors).includes(true)) {
      emailjs
        .send(
          SERVICE_ID,
          TEMPLATE_ID,
          {
            from_name: formValues.name,
            from_email: formValues.email,
            message: formValues.description,
          },
          USER_ID
        )
        .then((result) => {
          setSuccessMessage("Message successfully sent!");
          setErrorMessage("");
          setFormValues({ name: "", email: "", description: "" });
        })
        .catch((error) => {
          setErrorMessage("Failed to send the message. Please try again.");
          setSuccessMessage("");
        });
    }
  };

  return (
    <Box
      sx={{
        height: "auto",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        paddingBottom: "100px",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          padding: "100px 20px",
          fontFamily: "Montserrat, sans-serif",
          marginTop: "100px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "28px", md: "36px" },
            mb: 2,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
          }}
        >
          BOOK A FREE CONSULTATION TODAY
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: "500",
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
          }}
        >
          Have any questions or want to work together? Drop me a message below.
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          margin: "auto",
          padding: 4,
          backgroundColor: "rgba(44, 50, 59, 0.8)", // Semi-transparent background
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: "18px",
            marginBottom: 3,
            fontWeight: "500",
          }}
        >
          Let’s connect—drop a message!
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={formValues.name}
            onChange={handleChange}
            error={formErrors.name}
            helperText={formErrors.name ? "Name is required" : ""}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "#7e7e7e" },
                "&.Mui-focused fieldset": { borderColor: "white" },
              },
            }}
          />
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={formValues.email}
            onChange={handleChange}
            error={formErrors.email}
            helperText={formErrors.email ? "Email is required" : ""}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "#7e7e7e" },
                "&.Mui-focused fieldset": { borderColor: "white" },
              },
            }}
          />
          <TextField
            label="Message"
            name="description"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            multiline
            rows={4}
            value={formValues.description}
            onChange={handleChange}
            error={formErrors.description}
            helperText={formErrors.description ? "Message is required" : ""}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "#7e7e7e" },
                "&.Mui-focused fieldset": { borderColor: "white" },
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginTop: 3,
              padding: "12px",
              backgroundColor: "#bec4bf",
              color: "black",
              fontWeight: "bold",
              fontSize: "16px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#0bb822",
              },
            }}
          >
            Submit
          </Button>
        </form>

        {/* Display success or error messages */}
        {successMessage && (
          <Typography
            sx={{
              color: "#0bb822",
              marginTop: 3,
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography
            sx={{
              color: "red",
              marginTop: 3,
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ContactPage;
