import { Box, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThirdCard from "./ThirdCard";
import FourthCard from "./FourthCard";

const Red = () => {
  return (
    <Box sx={{ maxWidth: "90%", margin: "auto", marginBottom: "100px" }}>
      <Box
        sx={{
          flex: 1,
          textAlign: "left",
          padding: "20px 0 20px 20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            position: "relative",
            paddingBottom: "20px",
          }}
        >
          <ArrowDownwardIcon
            sx={{
              transform: "rotate(-45deg)",
              fontSize: "30px",
              marginRight: "8px",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "black",
              textTransform: "uppercase",
            }}
          >
            A Few of My Creations
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "relative",
          marginTop: "1px",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none",
            }}
          />

          <Box
            sx={{
              overflowY: "scroll",
              scrollBehavior: "smooth",
              "::-webkit-scrollbar": {
                width: "8px",
              },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
              height: "100%",
              position: "relative",
              zIndex: 0,
            }}
          >
            <FourthCard />
            <SecondCard />
            <ThirdCard />
            <FirstCard />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Red;
